import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";

import IconBlack from "../images/svg/icon-black.inline.svg";

const ZepelMethod = () => {
  const ref = useRef<HTMLDivElement>(null);
  const children = gsap.utils.selector(ref);

  useEffect(() => {
    gsap.fromTo(
      children(".box"),
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.25,
        duration: 0.5,
        scrollTrigger: {
          trigger: children("section"),
          start: "top center",
          end: "center center",
          scrub: 1,
        },
      }
    );
  }, []);

  return (
    <div ref={ref} id="zepelMethod">
      <section className="section flex flex-col-reverse justify-end max-w-screen-4xl m-auto mt-36 lg:mt-0 lg:flex-row">
        <div className="z-10 lg:basis-3/5 lg:relative">
          <div className="hidden lg:block">
            <StaticImage
              src="../images/webp/guy-stitching.webp"
              alt="factory stitching garment"
            />
          </div>
          <ul className="z-10 p-8 space-y-4 bg-zepelMethod bg-center mt-6 pb-36 lg:bg-none lg:absolute lg:right-0 lg:left-0 lg:bottom-0 lg:top-0 lg:mt-0 lg:p-0 lg:z-50 2xl:text-xl">
            <li
              key={1}
              className="box bg-white rounded-3xl border-4 border-zepelYellow font-light lg:absolute lg:h-fit lg:bottom-0 lg:top-4 lg:right-52 2xl:p-1 2xl:py-3 2xl:right-48 3xl:p-4 2xl:w-[26rem] 3xl:w-[29rem] 4xl:right-[21rem]"
            >
              <div className="flex space-x-10 m-2 px-6 items-center lg:justify-evenly">
                <p>1</p>
                <p>place a product inquiry</p>
              </div>
            </li>
            <li
              key={2}
              className="box bg-white rounded-3xl border-4 border-zepelYellow font-light lg:absolute lg:h-fit lg:top-24 lg:-right-10 lg:w-96 2xl:w-[26rem] 2xl:p-1 2xl:py-3 2xl:top-32 3xl:p-4 3xl:top-36 3xl:w-[29rem] 4xl:right-16"
            >
              <div className="flex space-x-10 m-2 px-6 items-center lg:justify-evenly">
                <p>2</p>
                <p>
                  we get back to you with the range of services we can offer
                </p>
              </div>
            </li>
            <li
              key={3}
              className="box bg-white rounded-3xl border-4 border-zepelYellow font-light lg:absolute lg:h-fit lg:top-56 lg:right-28 lg:w-96 2xl:w-[26rem] 2xl:p-1 2xl:py-3 2xl:right-48 2xl:top-72 3xl:p-4 3xl:top-[22rem] 3xl:right-48 3xl:w-[29rem] 4xl:right-[21rem] 4xl:top-80"
            >
              <div className="flex space-x-10 m-2 px-6 items-center lg:justify-evenly">
                <p>3</p>
                <p>lock the project and we start working</p>
              </div>
            </li>
            <li
              key={4}
              className="box bg-white rounded-3xl border-4 border-zepelYellow font-light lg:absolute lg:h-fit lg:top-[22rem] lg:-right-10 lg:w-96 2xl:w-[26rem] 2xl:p-1 2xl:py-3 2xl:top-[28rem] 3xl:p-4 3xl:top-[32rem] 3xl:w-[29rem] 4xl:right-16"
            >
              <div className="flex space-x-10 m-2 px-6 items-center lg:justify-evenly">
                <p>4</p>
                <p>fabric and print samples sent for approval</p>
              </div>
            </li>
            <li
              key={5}
              className="box bg-white rounded-3xl border-4 border-zepelYellow font-light lg:absolute lg:h-fit lg:top-[30rem] lg:right-28 lg:w-96 2xl:w-[26rem] 2xl:p-1 2xl:py-3 2xl:top-[38rem] 2xl:right-48 3xl:p-4 3xl:w-[29rem] 3xl:top-[43rem] 3xl:right-48 4xl:right-[21rem]"
            >
              <div className="flex space-x-10 m-2 px-6 items-center lg:justify-evenly">
                <p>5</p>
                <p>
                  on approval, we make the order and ship it off to you, ready
                  for the retail outlets
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="text-2xl w-80 ml-10 lg:basis-2/5 lg:mt-14 lg:ml-14 2xl:ml-28">
          <p className="text-5xl lg:text-6xl">The</p>
          <div className="w-64 mb-4 lg:pb-2 lg:w-80">
            <IconBlack />
          </div>
          <p className="text-5xl lg:text-6xl">method</p>
          <p>
            <br />
            {/* <br /> */}
            our 5 step MAGIC method for your success
          </p>
        </div>
      </section>
    </div>
  );
};

export default ZepelMethod;
