import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

import { StaticImage } from "gatsby-plugin-image";

import IconBlack from "../images/svg/icon-black.inline.svg";
import PurpleLine from "../images/svg/purple-rectangle.inline.svg";

const ZepelAdvantage = () => {
  const ref = useRef<HTMLDivElement>(null);
  const children = gsap.utils.selector(ref);

  useEffect(() => {
    gsap.fromTo(
      children(".image"),
      { x: 1000 },
      {
        x: 0,
        stagger: 0.25,
        duration: 0.5,
        scrollTrigger: {
          trigger: children(".section"),
          start: "top center",
          end: "center center",
          scrub: 1,
        },
      }
    );
  }, []);

  return (
    <div ref={ref} id="advantage">
      <section className="section h-fit w-screen my-28 xl:mt-44">
        <div className="flex space-x-24 max-w-screen-3xl m-auto">
          <div className="flex flex-col space-y-12 lg:space-y-10 2xl:space-y-28">
            <div className="flex flex-col ml-4 lg:ml-10">
              <p className="text-5xl 2xl:text-7xl">The</p>
              <div className="w-52 lg:w-64 2xl:w-96">
                <IconBlack className="mt-3 lg:mb-0" />
              </div>
              <br />
              <p className="text-5xl 2xl:text-7xl">advantage</p>
              <br />
              <p className="hidden lg:text-xl 2xl:text-2xl lg:block">
                How we're different from the rest.
              </p>
            </div>
            <div className="lg:hidden w-full mx-4">
              <div className="w-44 absolute z-10">
                <StaticImage
                  src="../images/webp/zepel-advantage.webp"
                  alt="zepel advantage 1"
                />
              </div>
              <div className="lg:absolute -z-10 right-0 left-36 top-36 w-80 ml-auto mt-16">
                <StaticImage
                  src="../images/webp/zepel-advantage-2.webp"
                  alt="zepel advantage 2"
                />
              </div>
            </div>
            <ul className="text-lg lg:text-xl 2xl:text-2xl ml-12 lg:ml-16 space-y-4">
              <li>Design Domination</li>
              <li>Meticulous Quality Checks</li>
              <li>Sustainability Centric</li>
              <li>Supplier Network Advantage</li>
              <li>Quick Turnaround Times</li>
              <li>Free Consultancy</li>
              <li>Diverse Portfolio</li>
            </ul>
          </div>
          <div className="hidden lg:block lg:relative w-full">
            <div className="image w-96 relative xl:w-[30rem] 2xl:w-[36rem]">
              <StaticImage
                src="../images/webp/zepel-advantage.webp"
                alt="zepel advantage 1"
              />
              <div className="image absolute left-1/2 -bottom-3">
                <PurpleLine />
              </div>
            </div>
            <div className="image absolute -z-10 right-0 left-36 top-36 lg:top-12 2xl:top-36 2xl:left-48 lg:w-[54rem]">
              <StaticImage
                src="../images/webp/zepel-advantage-2.webp"
                alt="zepel advantage 2"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ZepelAdvantage;
