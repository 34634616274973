import React, { useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { StaggerTextReveal } from "stagger-text-reveal-animation";

import Icon from "../images/svg/icon.inline.svg";

const Hero = (props: { modalOpen: boolean; activateModal: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const children = gsap.utils.selector(ref);

  useEffect(() => {
    gsap.fromTo(
      children(".hero-image"),
      { x: 2000 },
      { x: 0, duration: 1, autoAlpha: 1 }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      children(".words"),
      { opacity: 0 },
      { opacity: 1, stagger: 0.75, autoAlpha: 1 }
    );
  }, []);

  useEffect(() => {
    gsap.to(children(".staggeredWords"), { autoAlpha: 1 });
  }, []);

  return (
    <section
      className="w-screen h-fit flex flex-row text-white m-auto max-w-screen-4xl lg:h-screen"
      ref={ref}
    >
      <div className="bg-black h-full w-full md:h-[94%] md:w-7/12">
        <div className="ml-8 mt-8 2xl:ml-16 2xl:mt-16">
          <div className="w-56 pl-1 md:w-72">
            <Icon />
          </div>
          <div className="flex flex-col justify-end mt-8 sm:justify-start sm:w-full md:h-4/5 md:mt-20 2xl:mt-28 xl:space-y-12">
            <div className="z-10">
              <h1 className="words text-3xl max-w-[17ch] sm:max-w-[13ch] sm:text-5xl 2xl:text-7xl md:hidden">
                Empowering brands through unparalleled
              </h1>
              <div className="staggeredWords hidden invisible md:block">
                <StaggerTextReveal
                  text={"Empowering brands"}
                  height={60}
                  duration={0.5}
                  stagger={75}
                />
                <StaggerTextReveal
                  text={"through unparalleled"}
                  height={60}
                  duration={0.5}
                  stagger={75}
                />
                <StaggerTextReveal
                  text={"sourcing"}
                  height={60}
                  duration={0.5}
                  stagger={75}
                />
              </div>
              <h1 className="text-3xl sm:text-5xl w-fit bg-black pr-4 sm:bg-transparent md:hidden 2xl:text-7xl">
                sourcing
              </h1>
            </div>
            <div className="h-80 w-10/12 relative ml-auto sm:hidden">
              <StaticImage
                src="../images/webp/hero-image.webp"
                alt="Zepel hero image"
                className="absolute bottom-0 -top-8"
                loading="eager"
                // layout="fullWidth"
              />
            </div>
            <div className="relative -top-10 sm:static">
              <p className="words invisible text-lg max-w-md mr-6 z-10 bg-black pt-2 sm:bg-transparent sm:max-w-xs sm:text-xl sm:mb-32 lg:pt-32 xl:pt-0 md:max-w-sm lg:ml-auto 2xl:pt-10 2xl:text-2xl 2xl:max-w-lg">
                Zepel is a full-service textile sourcing solutions provider
                based in Pakistan. Providing end to end services from design to
                manufacturing to shipping, join us as we help empower your brand
                with the best supply chain solutions in the market.
              </p>
            </div>
          </div>
          {props.modalOpen ? null : (
            <button
              onClick={props.activateModal}
              className="flex items-center justify-center w-36 border-2 bottom-4 right-4 border-zepelYellow rounded-full fixed bg-black z-50 sm:w-48 2xl:w-56 2xl:h-20 2xl:bottom-12 2xl:right-12"
            >
              <p className="pb-2 pt-3 sm:pt-4 px-6 sm:text-xl 2xl:text-2xl">
                get a quote
              </p>
            </button>
          )}
        </div>
      </div>
      <div className="hero-image hidden invisible sm:block md:w-5/12 opacity-0">
        <StaticImage
          src="../images/webp/hero-image.webp"
          alt="Zepel hero image"
          className="h-full w-full"
          loading="eager"
        />
      </div>
    </section>
  );
};

export default Hero;
