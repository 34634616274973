import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import SEO from "../pages/seo";
import CustomerForm from "../sections/customerForm";
import Hero from "../sections/hero";
import Qualitites from "../sections/qualities";
import ZepelMethod from "../sections/zepelMethod";
import Services from "../sections/services";
import ProductPortfolio from "../sections/productPortfolio";
import ZepelLocations from "../sections/zepelLocations";
import Footer from "../sections/footer";
import ZepelAdvantage from "../sections/zepelAdvantage";

import Test from "../sections/test";

const IndexPage = () => {
  gsap.registerPlugin(ScrollTrigger);

  const [modalOpen, setModalOpen] = useState(false);

  const activateModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  return (
    <main className="overflow-clip">
      <SEO />
      <CustomerForm modalOpen={modalOpen} activateModal={activateModal} />
      <Hero modalOpen={modalOpen} activateModal={activateModal} />
      <Qualitites />
      <ZepelMethod />
      <Services />
      <ProductPortfolio />
      <ZepelLocations />
      <ZepelAdvantage />
      <Footer />
    </main>
  );
};

export default IndexPage;
