import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";

import PurpleRectangle from "../images/svg/purple-rectangle.inline.svg";
import LogoOutline from "../images/svg/logo-outline.inline.svg";
import ServicesLine from "../images/svg/services-line.inline.svg";
import PurpleLine from "../images/svg/purple-line.inline.svg";

const Services = () => {
  const ref = useRef<HTMLDivElement>(null);
  const children = gsap.utils.selector(ref);

  useEffect(() => {
    gsap.fromTo(
      children(".purple-rectangle"),
      { x: 2000 },
      {
        x: 0,
        duration: 1.25,
        scrollTrigger: {
          trigger: children(".section"),
          start: "-600px bottom",
          end: "top top",
          scrub: 1,
        },
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      children(".yellow-line"),
      { width: 0 },
      {
        width: "100%",
        duration: 1.25,
        scrollTrigger: {
          trigger: children(".section"),
          start: "top center",
          end: "center center",
          scrub: 1,
        },
      }
    );
  }, []);

  const [selectedService, setSelectedService] = useState("fabric");
  const [serviceImage, setServiceImage] = useState(<></>);
  const [serviceDetails, setServiceDetails] = useState("");

  useEffect(() => {
    gsap.fromTo(
      children(".service"),
      { opacity: 0 },
      { opacity: 1, duration: 0.75 }
    );
  }, [selectedService]);

  useEffect(() => {
    if (selectedService === "fabric") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/thread.png"
          alt=""
          className="absolute -left-12 top-3"
        />
      );
      setServiceDetails(
        "We select and source raw materials that truly fit your needs. Fabrics, trims and accessories that are responsibly sourced and sustainable."
      );
    } else if (selectedService === "design") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/designing.png"
          alt=""
          className="absolute left-0 top-10"
        />
      );
      setServiceDetails(
        "With industry leading graphic, textile and fashion designers on our panel, you can leave all your designing requirements on us."
      );
    } else if (selectedService === "stitch") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/stitching.png"
          alt=""
          className="absolute -left-4 top-5"
        />
      );
      setServiceDetails(
        "Every stitch is done with care, with our suppliers adhering to the highest standards of production. We manufacture the best products in the fastest lead times."
      );
    } else if (selectedService === "print") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/printing.png"
          alt=""
          className="absolute -left-0 top-8"
        />
      );
      setServiceDetails(
        "From screen to digital, sublimation, and a plethora of other techniques, we can cater every printing requirement you have."
      );
    } else if (selectedService === "pack") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/packaging.png"
          alt=""
          className="absolute -left-8 top-6"
        />
      );
      setServiceDetails(
        "Hangtags, stickers, polybags, boxes. All packaging material can be designed, sampled and manufactured on demand."
      );
    } else if (selectedService === "quality") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/qualityControl.png"
          alt=""
          className="absolute -left-0 top-8"
        />
      );
      setServiceDetails(
        "Our team of seasoned quality control personnel make sure your product reaches you with no defects at all."
      );
    } else if (selectedService === "logistics") {
      setServiceImage(
        <StaticImage
          src="../images/png/services/logistics.png"
          alt=""
          className="absolute -left-0 top-10"
        />
      );
      setServiceDetails(
        "We guarantee quick logictics solutions for your orders. A number of options and packages that best suit your timelines."
      );
    }
  }, [selectedService]);

  return (
    <div ref={ref} id="services">
      <section className="section w-screen lg:h-fit lg:relative bg-black text-white">
        <div className="bg-black lg:w-full lg:h-full lg:absolute -top-32 -z-10">
          <PurpleRectangle className="purple-rectangle hidden lg:absolute right-32 -top-3 4xl:right-80 lg:block" />
        </div>
        <div className="flex flex-col items-center justify-center max-w-screen-lg m-auto 2xl:max-w-screen-xl 4xl:max-w-screen-2xl">
          <div className="lg:w-full z-10 w-32 ml-auto mr-8 mb-4 relative 2xl:mt-32">
            <div className="absolute -bottom-16 w-32 lg:static lg:ml-auto lg:mt-20 xl:mt-10 lg:mr-2 xl:mr-4 lg:w-44 xl:w-40 2xl:w-52">
              <LogoOutline />
            </div>
          </div>
          <div className="flex flex-row-reverse items-center mt-44 lg:flex-row lg:space-x-8 lg:w-full lg:mt-16 xl:mt-10 lg:max-w-screen-lg 2xl:max-w-screen-xl 2xl:my-20 4xl:max-w-screen-2xl">
            <div className="w-48 h-1 lg:basis-4/5">
              <ServicesLine className="yellow-line" />
            </div>
            <h1 className="text-5xl 2xl:text-7xl pl-14 pr-4 lg:p-0">
              services
            </h1>
          </div>
          <div className="w-full lg:max-w-screen-lg 2xl:max-w-screen-xl 4xl:max-w-screen-2xl 2xl:mb-20">
            <ul className="text-2xl flex flex-col lg:flex-row items-center justify-between mb-36 lg:hidden">
              <li key={0} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">
                  fabric /<br />
                  raw material
                  <br />
                  sourcing
                </p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage src="../images/png/services/thread.png" alt="" />
                </div>
                <p className="w-full mx-8">
                  We select and source raw materials that truly fit your needs.
                  Fabrics, trims and accessories that are responsibly sourced
                  and sustainable.
                </p>
              </li>
              <li key={1} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">designing</p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage
                    src="../images/png/services/designing.png"
                    alt=""
                  />
                </div>
                <p className="w-full mx-8">
                  With industry leading graphic, textile and fashion designers
                  on our panel, you can leave all your designing requirements on
                  us.
                </p>
              </li>
              <li key={2} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">
                  stitching /
                  <br />
                  manufacturing
                </p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage
                    src="../images/png/services/stitching.png"
                    alt=""
                  />
                </div>
                <p className="w-full mx-8">
                  Every stitch is done with care, with our suppliers adhering to
                  the highest standards of production. We manufacture the best
                  products in the fastest lead times.
                </p>
              </li>
              <li key={3} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">printing</p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage
                    src="../images/png/services/printing.png"
                    alt=""
                  />
                </div>
                <p className="w-full mx-8">
                  From screen to digital, sublimation, and a plethora of other
                  techniques, we can cater every printing requirement you have.
                </p>
              </li>
              <li key={4} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">
                  packaging /
                  <br />
                  branding
                </p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage
                    src="../images/png/services/packaging.png"
                    alt=""
                  />
                </div>
                <p className="w-full mx-8">
                  Hangtags, stickers, polybags, boxes. All packaging material
                  can be designed, sampled and manufactured on demand.
                </p>
              </li>
              <li key={5} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">quality control</p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage
                    src="../images/png/services/qualityControl.png"
                    alt=""
                  />
                </div>
                <p className="w-full mx-8">
                  Our team of seasoned quality control personnel make sure your
                  product reaches you with no defects at all.
                </p>
              </li>
              <li key={6} className="flex flex-row flex-wrap items-center">
                <p className="ml-8 grow">logistics</p>
                <div className="w-44 h-44 flex items-center justify-center">
                  <StaticImage
                    src="../images/png/services/logistics.png"
                    alt=""
                  />
                </div>
                <p className="w-full mx-8">
                  We guarantee quick logictics solutions for your orders. A
                  number of options and packages that best suit your timelines.
                </p>
              </li>
            </ul>
            <ul className="hidden lg:text-xl lg:flex lg:flex-row lg:items-center lg:justify-between lg:mt-6">
              <li>
                <button
                  onClick={() => setSelectedService("fabric")}
                  className="text-left"
                >
                  {selectedService === "fabric" ? (
                    <p className="text-white">
                      fabric /<br />
                      raw material
                      <br />
                      sourcing
                    </p>
                  ) : (
                    <p className="text-gray-500">
                      fabric /<br />
                      raw material
                      <br />
                      sourcing
                    </p>
                  )}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setSelectedService("design")}
                  className="text-left"
                >
                  {selectedService === "design" ? (
                    <p className="text-white">designing</p>
                  ) : (
                    <p className="text-gray-500">designing</p>
                  )}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setSelectedService("stitch")}
                  className="text-left"
                >
                  {selectedService === "stitch" ? (
                    <p className="text-white">
                      stitching /<br />
                      manufacturing
                    </p>
                  ) : (
                    <p className="text-gray-500">
                      stitching /<br />
                      manufacturing
                    </p>
                  )}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setSelectedService("print")}
                  className="text-left"
                >
                  {selectedService === "print" ? (
                    <p className="text-white">printing</p>
                  ) : (
                    <p className="text-gray-500">printing</p>
                  )}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setSelectedService("pack")}
                  className="text-left"
                >
                  {selectedService === "pack" ? (
                    <p className="text-white">
                      packaging /<br />
                      branding
                    </p>
                  ) : (
                    <p className="text-gray-500">
                      packaging /<br />
                      branding
                    </p>
                  )}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setSelectedService("quality")}
                  className="text-left"
                >
                  {selectedService === "quality" ? (
                    <p className="text-white">quality control</p>
                  ) : (
                    <p className="text-gray-500">quality control</p>
                  )}
                </button>
              </li>
              <li>
                <button
                  onClick={() => setSelectedService("logistics")}
                  className="text-left"
                >
                  {selectedService === "logistics" ? (
                    <p className="text-white">logistics</p>
                  ) : (
                    <p className="text-gray-500">logistics</p>
                  )}
                </button>
              </li>
            </ul>
            <div className="mt-4">
              <PurpleLine />
            </div>
          </div>
          <div className="hidden lg:flex lg:flex-row lg:items-center lg:max-w-screen-lg lg:mt-8 2xl:max-w-screen-xl 4xl:max-w-screen-2xl lg:mb-24 xl:mb-32 2xl:mb-40">
            <div className="service relative w-44 h-44">{serviceImage}</div>
            <p className=" service text-xl 2xl:text-2xl w-full">
              {serviceDetails}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
