import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

import Z from "../images/svg/z.inline.svg";
import { features } from "../app/data";

const Qualitites = () => {
  const ref = useRef<HTMLDivElement>(null);
  const children = gsap.utils.selector(ref);

  useEffect(() => {
    gsap.fromTo(
      children(".box"),
      { opacity: 0 },
      {
        opacity: 1,
        stagger: 0.8,
        // duration: 5,
        scrollTrigger: {
          trigger: children("section"),
          start: "top center",
          end: "center center",
          scrub: 1,
        },
      }
    );
  }, []);

  const featuresJSX = features.map((obj, index) => {
    return (
      <li key={index} className="box mr-10 my-8 lg:mx-8">
        <h2 className="text-xl font-black mb-2 lg:text-base 2xl:text-xl">
          {obj.heading}
        </h2>
        <p className="text-xl lg:text-base 2xl:text-xl">{obj.description}</p>
      </li>
    );
  });

  return (
    <div ref={ref} id="about">
      <section className="section w-screen flex flex-col items-start justify-start ml-10 max-w-screen-4xl md:h-screen lg:items-center lg:justify-center lg:ml-0 lg:flex-row 2xl:m-auto">
        <div className="basis-1/4 lg:relative lg:-left-6">
          <div className="w-52 mt-28 lg:mt-0 lg:w-60 2xl:w-72">
            <Z />
          </div>
        </div>
        <ul className="basis-3/4 flex flex-col align-top justify-center mr-12 lg:flex-row lg:mr-0 2xl:mr-12">
          {featuresJSX}
        </ul>
      </section>
    </div>
  );
};

export default Qualitites;
