// import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ZepelMap from "../images/svg/zepel-locations.inline.svg";

const ZepelLocations = () => {
  return (
    <section className="w-screen lg:h-screen" id="locations">
      <div className="h-full w-full flex mt-40 lg:items-center justify-center max-w-screen-3xl m-auto xl:mt-40 2xl:mt-48">
        <div className="w-11/12 space-y-8">
          <h2 className="text-5xl lg:text-6xl lg:text-right 2xl:text-7xl">
            Connected Globally
          </h2>
          <div className="w-full">
            <ZepelMap />
          </div>
          {/* <StaticImage
            src="../images/webp/zepel-locations.webp"
            alt="zepel locations"
            className="text-center"
          /> */}
        </div>
      </div>
    </section>
  );
};

export default ZepelLocations;
