import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";
import { useArrayRef } from "../helperFunctions/arrayRef";

const ProductPortfolio = () => {
  const ref = useRef<HTMLDivElement>(null);
  const children = gsap.utils.selector(ref);
  const [sectionRefs, setSectionRef] = useArrayRef();
  // let sections = gsap.utils.toArray(".panel");

  useEffect(() => {
    const totalPanels = sectionRefs.current.length;
    ScrollTrigger.matchMedia({
      "(min-width: 1024px)": function () {
        gsap.to(sectionRefs.current, {
          xPercent: -100 * (totalPanels - 1),
          ease: "none",
          scrollTrigger: {
            trigger: children(".container"),
            pin: true,
            start: "top top",
            scrub: 1,
            end: "+=" + ref.current?.offsetWidth,
          },
        });
      },
    });
  }, []);

  const portfolioDesktop = (
    <ul className="hidden lg:flex space-x-8 text-white mx-8 2xl:mt-6">
      <li
        key={0}
        className="min-w-[85vw] h-fit border rounded-[4rem] p-8 border-black relative xl:min-w-[60vw] 2xl:min-w-[65vw]"
        ref={setSectionRef}
      >
        <StaticImage
          src="../images/webp/apparel-portfolio.webp"
          alt="apparel portfolio"
          className="max-h-full rounded-[4rem]"
        />
        <div className="relative">
          <div className="absolute bottom-0 left-0 -top-300 w-full bg-zepelBlack rounded-[4rem] p-10">
            <h3 className="text-7xl">apparel</h3>
            <p className="text-3xl">
              T Shirts | Hoodies | Sweatshirts | Denim | Chinos | Trousers |
              Sportswear | Performance apparel
            </p>
            <p className="text-3xl">
              Leather jackets | Gym wear | Swimwear | Tracksuits
            </p>
          </div>
        </div>
      </li>
      <li
        key={1}
        className="min-w-[85vw] h-fit border rounded-[4rem] p-8 border-black relative xl:min-w-[60vw] 2xl:min-w-[65vw]"
        ref={setSectionRef}
      >
        <StaticImage
          src="../images/webp/textiles-portfolio.webp"
          alt="home textiles"
          className="max-h-full rounded-[4rem]"
        />
        <div className="relative">
          <div className="absolute bottom-0 left-0 -top-300 w-full bg-zepelBlack rounded-[4rem] p-10">
            <h3 className="text-7xl">home textiles</h3>
            <p className="text-3xl">
              Bedsheets | Dining accessories | Towels | Linens
            </p>
          </div>
        </div>
      </li>
      <li
        key={2}
        className="min-w-[85vw] h-fit border rounded-[4rem] p-8 border-black relative xl:min-w-[60vw] 2xl:min-w-[65vw]"
        ref={setSectionRef}
      >
        <StaticImage
          src="../images/webp/accessories-portfolio.webp"
          alt="accessories"
          className="max-h-full rounded-[4rem]"
        />
        <div className="relative">
          <div className="absolute bottom-0 left-0 -top-300 w-full bg-zepelBlack rounded-[4rem] p-10">
            <h3 className="text-7xl">accessories</h3>
            <p className="text-3xl">
              Leather bags | Purses | Caps | Hats | Wallets | Pouches | Sleeves
            </p>
          </div>
        </div>
      </li>
    </ul>
  );

  const portfolio = (
    <ul className="space-y-24 lg:hidden">
      <li className="mx-5">
        <h2 className="text-5xl mb-2 mx-2">apparel</h2>
        <StaticImage
          src="../images/webp/apparel-portfolio.webp"
          alt="apparel portfolio"
        />
        <p className="text-xl mt-5 mx-2">
          T Shirts | Hoodies | Sweatshirts | Denim | Chinos | Trousers |
          Sportswear | Performance apparel | Leather jackers | Gym wear |
          Swimwear | Tracksuits
        </p>
      </li>
      <li className="mx-5">
        <h2 className="text-5xl mb-2 mx-2">home textiles</h2>
        <StaticImage
          src="../images/webp/textiles-portfolio.webp"
          alt="apparel portfolio"
          className="rounded-xl"
        />
        <p className="text-xl mt-5 mx-2">
          Bedsheets | Dining Accessories | Towels | Linens
        </p>
      </li>
      <li className="mx-5">
        <h2 className="text-5xl mb-2 mx-2">accessories</h2>
        <StaticImage
          src="../images/webp/accessories-portfolio.webp"
          alt="apparel portfolio"
          className="rounded-xl"
        />
        <p className="text-xl mt-5 mx-2">
          Leather bags | Purses | Caps | Hats | Wallets | Pouches | Sleeves
        </p>
      </li>
    </ul>
  );

  return (
    <div ref={ref} id="products">
      <section className="container w-screen lg:h-fit flex flex-col py-20 lg:py-0 m-auto max-w-screen-4xl">
        <h2 className="hidden text-6xl ml-20 lg:block lg:mb-4 lg:mt-24 xl:text-5xl 2xl:text-6xl">
          product portfolio
        </h2>
        {portfolio}
        {portfolioDesktop}
      </section>
    </div>
  );
};

export default ProductPortfolio;
