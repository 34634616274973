export const features = [
  {
    heading: "Get the Design Advantage",
    description: "ZEPEL prides itself in being leaders in design for the textile world. Whether it's a cool new street brand, or fresh linens for bedrooms, we at ZEPEL can design it all. A large network of established designers from every field in textile are available to bring your product to life."
  },
  {
    heading: "Fiber to Fashion",
    description: "From selecting the best fabric to your collection, to manufacturing and shipping it off, we at ZEPEL give you the complete end to end solution for your brand / business. We consult you on fabric, printing techniques, packaging solutions and help you get your product on your shelves. FAST."
  },
  {
    heading: "All Bespoke",
    description: "You just have to tell us what you are envisioning, and we take care of the rest. We guide you through the process, and make every inch of your sourcing experience tailor made to your needs. From your product to your shipping terms, everything is bespoke."
  }
]

export const slideshow = [
  {
    title: 'apparel',
    image: '../images/webp/apparel-portfolio.webp',
    imageAlt: 'apparel portfolio',
    categories: ['T Shirts', 'Hoodies', 'Sweatshirts', 'Denim', 'Chinos', 'Trousers', 'Sportswear', 'Performance apparel']
  }
]
