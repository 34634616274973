import React, { useEffect, useState } from "react";

import Select from "react-select";
import { RotatingSquare } from "react-loader-spinner";

import IconOutline from "../images/svg/icon-black.inline.svg";

// interface FormValues {
//   name: string;
//   company: string;
//   emailId: string;
//   mobileNumber: number;
//   productInterest: string[];
//   referenceImage?: File;
//   message: string;
// }

function encode(data: any) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const CustomerForm = (props: {
  modalOpen: boolean;
  activateModal: () => void;
}) => {
  const [state, setState] = React.useState({});
  const [productInterest, setProductInterest] = useState("tshirts");
  const [minQty, setMinQty] = useState(200);
  const [formSubmit, setFormSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(true);

  const handleChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleAttachment = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.files[0] });
  };

  const formSubmitted = () => {
    setFormSubmit((prevValue) => !prevValue);
  };

  useEffect(() => {
    setFormSubmit(false);
    setSubmitLoading(false);
  }, [props.modalOpen]);

  const startSubmitAnimation = () => {
    setSubmitLoading(true);
    setTimeout(function () {
      formSubmitted();
    }, 2000);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    // console.log(state);
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => startSubmitAnimation())
      .catch((error) => alert(error));
  };

  const productOptions = [
    { value: "tshirts", label: "T shirts" },
    { value: "hoodies", label: "Hoodies" },
    { value: "sweatshirts", label: "Sweatshirts" },
    { value: "denim", label: "Denim" },
    { value: "chinos", label: "Chinos" },
    { value: "trousers", label: "Trousers" },
    { value: "sportswear", label: "Sportswear" },
    { value: "performanceApparel", label: "Performance Apparel" },
    { value: "leatherJackets", label: "Leather jackets" },
    { value: "gymwear", label: "Gymwear" },
    { value: "swimwear", label: "Swimwear" },
    { value: "tracksuits", label: "Tracksuits" },
    { value: "bedsheets", label: "Bedsheets" },
    { value: "drapery", label: "Drapery" },
    { value: "curtains", label: "Curtains" },
    { value: "diningAccessories", label: "Dining accessories" },
    { value: "towels", label: "Towels" },
    { value: "linens", label: "Linens" },
    { value: "leatherBags", label: "Leather bags" },
    { value: "purses", label: "Purses" },
    { value: "caps", label: "Caps" },
    { value: "hats", label: "Hats" },
    { value: "wallets", label: "Wallets" },
    { value: "pouches", label: "Pouches" },
    { value: "sleeves", label: "Sleeves" },
  ];
  // const productDefaultOption = productOptions[0];

  const serviceOptions = [
    { value: "designing", label: "Designing" },
    { value: "stitching", label: "Stitching/manufacturing" },
    { value: "printing", label: "Printing" },
    { value: "packaging", label: "Packaging & branding" },
    { value: "shipping", label: "Shipping" },
  ];
  // const serviceDefaultOption = serviceOptions[0];

  const selectProduct = (option: any) => {
    setProductInterest(option.value);
    let e = { target: { name: "", value: "" } };
    e["target"]["name"] = "productInterest";
    e["target"]["value"] = option.label;
    handleChange(e);
  };

  const selectServices = (options: any) => {
    let e = { target: { name: "", value: "" } };
    e["target"]["name"] = "servicesRequested";
    let values = "";
    for (let i = 0; i < options.length; i++) {
      if (i === 0) values = options[i].label;
      else values = values + ", " + options[i].label;
    }
    e["target"]["value"] = values;
    handleChange(e);
  };

  useEffect(() => {
    if (productInterest === "tshirts") {
      setMinQty(200);
    } else if (productInterest === "hoodies") {
      setMinQty(400);
    } else if (productInterest === "sweatshirts") {
      setMinQty(200);
    } else if (productInterest === "denim") {
      setMinQty(500);
    } else if (productInterest === "chinos") {
      setMinQty(500);
    } else if (productInterest === "trousers") {
      setMinQty(500);
    } else if (productInterest === "sportswear") {
      setMinQty(200);
    } else if (productInterest === "performanceApparel") {
      setMinQty(200);
    } else if (productInterest === "leatherJackets") {
      setMinQty(50);
    } else if (productInterest === "gymwear") {
      setMinQty(200);
    } else if (productInterest === "swimwear") {
      setMinQty(200);
    } else if (productInterest === "tracksuits") {
      setMinQty(200);
    } else if (productInterest === "bedsheets") {
      setMinQty(200);
    } else if (productInterest === "drapery") {
      setMinQty(200);
    } else if (productInterest === "curtains") {
      setMinQty(100);
    } else if (productInterest === "diningAccessories") {
      setMinQty(100);
    } else if (productInterest === "towels") {
      setMinQty(500);
    } else if (productInterest === "linens") {
      setMinQty(200);
    } else if (productInterest === "leatherBags") {
      setMinQty(50);
    } else if (productInterest === "purses") {
      setMinQty(100);
    } else if (productInterest === "caps") {
      setMinQty(200);
    } else if (productInterest === "hats") {
      setMinQty(200);
    } else if (productInterest === "wallets") {
      setMinQty(200);
    } else if (productInterest === "pouches") {
      setMinQty(200);
    } else if (productInterest === "sleeves") {
      setMinQty(200);
    }
  }, [productInterest]);

  return (
    <div
      className={
        props.modalOpen
          ? "w-screen bg-white fixed z-50 top-0 bottom-0 overflow-y-scroll lg:h-screen"
          : "w-screen bg-white fixed z-50 top-0 bottom-0 overflow-y-scroll left-[4000px]"
      }
    >
      <div className="flex flex-col p-8 space-y-14 lg:max-w-7xl lg:m-auto lg:px-32 lg:pt-16">
        <div className="flex items-center justify-between">
          {/* <div className="basis-3/4 lg:relative">
            <div className="w-44 lg:rotate-[270deg] lg:w-80 lg:absolute lg:top-40 lg:right-[1400px]">
              <IconOutline />
            </div>
          </div>
          <button
            className="basis-1/4 flex flex-col items-end justify-center lg:ml-auto lg:mr-40 lg:mt-8"
            onClick={props.activateModal}
          >
            <p className="text-2xl pt-2 lg:text-5xl">X</p>
          </button> */}
          <div className="basis-3/4 lg:basis-1/2">
            <div className="w-44 lg:w-64">
              <IconOutline />
            </div>
          </div>
          <button
            className="basis-1/4 flex flex-col items-end justify-center lg:basis-1/2"
            onClick={props.activateModal}
          >
            <p className="text-2xl pt-2 lg:text-4xl">X</p>
          </button>
        </div>
        <form
          name="place-order"
          method="post"
          action="/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          className="space-y-10 text-2xl lg:flex lg:flex-wrap lg:justify-between lg:space-x-2"
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="place-order" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Name</label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              className="w-full border-b border-black"
            />
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Company</label>
            <input
              type="text"
              name="company"
              onChange={handleChange}
              className="w-full border-b border-black"
            />
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Email Address</label>
            <input
              type="text"
              name="emailAddress"
              onChange={handleChange}
              className="w-full border-b border-black"
            />
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Mobile #</label>
            <input
              type="number"
              name="mobileNumber"
              onChange={handleChange}
              className="w-full border-b border-black"
            />
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Product interest</label>
            <Select
              options={productOptions}
              onChange={selectProduct}
              name="productInterest"
            />
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Services requested</label>
            <Select
              options={serviceOptions}
              isMulti={true}
              onChange={selectServices}
              name="servicesRequested"
            />
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label htmlFor="">Quantity</label>
            <input
              type="number"
              name="quantity"
              onChange={handleChange}
              className="w-full border-b border-black"
            />
            <label htmlFor="" className="text-lg">
              Minimum Order Quantity - {minQty}
            </label>
          </div>
          <div className="flex flex-col space-y-2 lg:basis-[45%]">
            <label>Reference Image</label>
            <input
              type="file"
              name="attachment"
              onChange={handleAttachment}
              className=""
            />
          </div>
          <div className="flex flex-col space-y-2 m-auto">
            <label>Message</label>
            <textarea
              id="message"
              name="message"
              rows={4}
              cols={100}
              onChange={handleChange}
              className="border rounded-3xl border-black p-4 lg:max-w-5xl"
            />
          </div>
          <div className="text-4xl w-full flex items-center justify-center lg:ml-auto lg:justify-end">
            {formSubmit ? (
              <label className="mt-8">submitted</label>
            ) : (
              <div className="">
                {submitLoading ? (
                  <div className="text-black w-18 mb-4">
                    <RotatingSquare
                      ariaLabel="rotating-square"
                      visible={true}
                      color="grey"
                    />
                  </div>
                ) : (
                  <button type="submit" className="mt-8">
                    submit
                  </button>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerForm;

// <>
// {props.modalOpen ? (
/* ) : null} */
/* </> */
// return (
// <div>
//   {props.modalOpen ? (
//     <div className="w-screen bg-white fixed z-50 top-0 bottom-0 overflow-y-scroll">
//       <div className="flex flex-col p-8 space-y-14">
//         <div className="flex items-center justify-between">
//           <div className="basis-3/4">
//             <div className="w-44">
//               <IconOutline />
//             </div>
//           </div>
//           <button
//             className="basis-1/4 flex flex-col items-end justify-center"
//             onClick={props.activateModal}
//           >
//             <p className="text-2xl pt-2">X</p>
//           </button>
//         </div>
//         <div className="space-y-12">
//           <div className="space-y-2">
//             <p className="text-2xl">Name</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Company</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Email Address</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Mobile #</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Product interest</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Services requested</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Quantity</p>
//             <input type="text" className="w-full border-b border-black" />
//           </div>
//           <div className="space-y-2">
//             <p className="text-2xl">Reference Image</p>
//             <p className="text-lg">upload</p>
//           </div>
//           <div className="space-y-8">
//             <p className="text-2xl">Message</p>
//             <input
//               type="text"
//               className="w-full border rounded-3xl h-60 border-black"
//             />
//           </div>
//           <button className="flex items-center justify-center w-full">
//             <p className="text-4xl">Submit</p>
//           </button>
//         </div>
//       </div>
//     </div>
//   ) : null}
// </div>
// );
