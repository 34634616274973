import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

import FacebookIcon from "../images/svg/facebook.inline.svg";
import InstagramIcon from "../images/svg/instagram.inline.svg";
// import LinkedInIcon from "../images/svg/linkedin.inline.svg";
import LogoOutline from "../images/svg/logo-outline.inline.svg";
import IconOutline from "../images/svg/icon.inline.svg";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <section className="w-screen h-full lg:h-screen lg:py-16 bg-black text-white">
      <div className="flex flex-col justify-between max-w-screen-2xl m-auto ml-8 space-y-12 lg:space-y-0 lg:justify-center xl:max-w-screen-xl 2xl:max-w-screen-2xl 4xl:max-w-screen-3xl xl:m-auto 2xl:px-8 2xl:pt-4">
        <div className="flex flex-col mt-16 space-y-2 lg:flex-row lg:justify-between lg:items-start xl:mt-0 lg:my-10 3xl:mb-24">
          <div className="space-y-6">
            <p className="text-5xl lg:text-5xl 2xl:text-6xl">get in touch</p>
            <p className="text-2xl 2xl:text-3xl">info@zepel.co</p>
          </div>
          <div className="flex flex-row space-x-6 lg:mr-8">
            <div className="w-12 lg:w-16">
              <a
                href="https://www.facebook.com/ZEPEL-MFG-Sourcing-103412909066734/"
                target="_blank"
              >
                <FacebookIcon />
              </a>
            </div>
            <div className="w-12 lg:w-16">
              <a
                href="https://www.instagram.com/zepelsourcing/"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </div>
            {/* <div className="w-12 lg:w-16">
              <LinkedInIcon />
            </div> */}
          </div>
        </div>
        <div className="space-y-12 lg:space-y-8 xl:space-y-14 2xl:space-y-20">
          <ul className="text-xl space-y-4 2xl:text-2xl">
            <li>
              <button>
                <Link to="about" spy={true} smooth={true}>
                  ABOUT
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link to="zepelMethod" spy={true} smooth={true}>
                  THE ZEPEL METHOD
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link to="services" spy={true} smooth={true}>
                  SERVICES
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link to="products" spy={true} smooth={true}>
                  PRODUCTS
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link to="locations" spy={true} smooth={true}>
                  CONNECTED GLOBALLY
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link to="advantage" spy={true} smooth={true}>
                  THE ZEPEL ADVANTAGE
                </Link>
              </button>
            </li>
          </ul>
          <div className="flex flex-col items-start justify-center  lg:space-x-8 lg:flex-row lg:justify-start lg:items-end">
            <div className="flex flex-col lg:items-center lg:justify-center space-y-6 mb-3">
              <div className="w-24 xl:w-20 2xl:w-32">
                <LogoOutline />
              </div>
              <div className="w-52 pb-8 lg:pb-0 xl:w-40 2xl:w-56">
                <IconOutline />
              </div>
              <div className="self-end hidden lg:block lg:self-center">
                <p className="text-2xl xl:text-xl 2xl:text-2xl">
                  all rights reserved
                </p>
              </div>
            </div>
            <div className="w-36 mb-5">
              <a href="https://bluebanana.agency/" target="_blank">
                <StaticImage
                  src="../images/png/blue-banana.png"
                  alt="powered by blue banana"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
